import React from 'react'
import PropTypes from 'prop-types'
import Button from '../Button'
import './style.scss'

export const OfflineModal = ({ className, onPrimaryButtonClick, title, message, primaryButtonText }) => {
  return (
    <div className={`dialog-backdrop ${className}`}>
      <dialog id="offline-dialog" open>
        <div className="offline-modal-wrapper">
          <p className="title">
            <strong>{title}</strong>
          </p>
          <p className="message">{message}</p>
          <Button onClick={onPrimaryButtonClick} text={primaryButtonText} />
        </div>
      </dialog>
    </div>
  )
}

OfflineModal.propTypes = {
  className: PropTypes.string,
  onPrimaryButtonClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  primaryButtonText: PropTypes.string.isRequired
}
