import styled from 'styled-components'

export default styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  .application-content-container {
    flex: 1;
    width: 100%;
    height: 100%;
    background: #f5f6fa;
    margin-right: 80px;
    &.show-right-bar {
      margin-right: 240px;
    }
    &.no-right-bar {
      margin-right: 0;
      background: ${(props) => props.theme.darkBackground};
    }
  }
  .offline-modal {
    &.hide {
      display: none;
    }
  }
`
