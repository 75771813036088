import { Socket } from 'phoenix-channels'
import { storage } from '../../utils'
import { updateNewNotificationSocket, updateCameraSocket } from './actions'

const SOCKET_URL = process.env.SOCKET_URL
const SOCKET_CAMERA_TYPE = 'Camera'

export const connectSocket = () => {
  return async (dispatch) => {
    const userLoginToken = storage.get('currentUserToken')
    const socket = new Socket(SOCKET_URL, { params: { token: userLoginToken } })
    socket.connect()
    const currentProjectId = Number(window.location.pathname.split('/')[3])
    const channel = socket.channel(`farmlab_backend:${currentProjectId}`)
    channel.join().receive('error', (resp) => {
      console.log('Unable to join topic', `farmlab_backend:${currentProjectId}`)
    })

    channel.on('new_notification', (payload) => {
      dispatch(updateNewNotificationSocket(payload))
      if (payload.notification_type === SOCKET_CAMERA_TYPE) {
        dispatch(updateCameraSocket(payload))
      }
    })
  }
}
