const MESSAGE = {
  EN: {
    error_dialog_title_default: 'Failure',
    error_dialog_message_default: 'Something wrong, Please try again.',
    ok_btn: 'OK',
    offline_modal_title: 'No internet connection',
    offline_modal_message: 'Please check your internet connection and try again.'
  },
  TH: {
    error_dialog_title_default: 'พบข้อผิดพลาด',
    error_dialog_message_default: 'กรุณาลองใหม่อีกครั้ง',
    ok_btn: 'ตกลง',
    offline_modal_title: 'ไม่มีการเชื่อมต่ออินเทอร์เน็ต',
    offline_modal_message: 'กรุณาตรวจสอบการเชื่อมต่ออินเทอร์เน็ตของคุณ แล้วลองอีกครั้ง'
  }
}

export default MESSAGE
