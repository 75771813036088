/* eslint-disable no-console */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Route, Switch, Redirect } from 'react-router-dom'
import { withAuth0 } from '@auth0/auth0-react'
import ApplicationLayoutStyled from './styledComponent'

import SelectSitePage from '../SelectSitePage/Loadable'
import DashboardPage from '../DashboardPage/Loadable'
import UserManagementPage from '../UserManagementPage/Loadable'
import ViolationDetailPage from '../ViolationDetailPage/Loadable'
import CameraZonePage from '../CameraZonePage/Loadable'
import ProjectPage from '../ProjectPageLayout/Loadable'
import ProjectDetailPage from '../ProjectDetailPage/Loadable'
import APIKeyPage from '../APIKeyPage/Loadable'
import NotificationSidebar from '../NotificationSidebar/Loadable'
import Sidebar from '../../components/Sidebar/Loadable'
import ModalNotification from '../../components/ModalNotification/Loadable'
import ModalOverlay from '../../components/ModalOverlay/Loadable'
import Spinner from '../../components/Spinner/Loadable'
import { OfflineModal } from '../../components/OfflineModal'
import * as LoginActions from '../LoginPage/actions'
import * as NotificationActions from '../NotificationSidebar/actions'
import * as SocketActions from '../NotificationSidebar/socket'
import * as ProjectDetailsActions from '../ProjectDetailPage/actions'
import * as ApplicationActions from './actions'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { FETCH_STATUS_REQUEST, FETCH_STATUS_SUCCESS, FETCH_STATUS_FAILURE, FRONTEND_ROUTES } from '../../utils'
import fetchErrorHandler from '../../utils/fetchErrorHandler'

import MESSAGE from './message'

const FEATURE_SITE = 'sites'
// eslint-disable-next-line no-unused-vars
const FEATURE_PROJECT = 'projects'

export class ApplicationLayout extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showNotificationSidebar: false,
      failureModalData: {
        show: false,
        title: '',
        message: '',
        onPrimaryButtonClick: undefined,
        primaryButtonText: ''
      },
      showOfflineModal: false
    }
  }

  async componentDidMount() {
    await this.checkUserLoggedInAndConnectSocket()
  }

  checkUserLoggedInAndConnectSocket = async () => {
    await this.props.isLoggedIn()
    const routeData = this.props.location.pathname.split('/')
    const feature = routeData[2]
    const projectId = routeData[3]
    const currentSiteId = routeData[4]
    if (feature === FEATURE_SITE && currentSiteId) {
      this.connectSocketNotifications()
    } else if (feature === FEATURE_PROJECT && projectId) {
      this.connectSocketForProjectManagePage()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    this._handleFetchUserFailure(prevProps)
    this._handleRouterSiteChanged(prevProps)
    this.handleFetchLogout(prevProps)
    this.handleFetchEditUser(prevProps)
    window.addEventListener('online', this.handleCloseOfflineModal)
    window.addEventListener('offline', this.handleShowOfflineModal)
  }

  componentWillUnmount() {
    window.removeEventListener('online', this.handleCloseOfflineModal)
    window.removeEventListener('offline', this.handleShowOfflineModal)
  }

  _handleFetchUserFailure = (prevProps) => {
    const { isAuthenticated } = this.props.auth0

    if (prevProps.loadUserStatus === FETCH_STATUS_REQUEST && this.props.loadUserStatus === FETCH_STATUS_FAILURE && !isAuthenticated) {
      this.handleFetchAutoLoginFailure()
    }
    if (prevProps.loadUserSSOStatus === FETCH_STATUS_REQUEST && this.props.loadUserSSOStatus === FETCH_STATUS_FAILURE) {
      this.handleFetchAutoLoginFailure()
    }
  }

  handleFetchEditUser(prevProps) {
    if (
      prevProps.loginPage.loadEditUser.fetchStatus === FETCH_STATUS_REQUEST &&
      this.props.loginPage.loadEditUser.fetchStatus === FETCH_STATUS_FAILURE
    ) {
      this.handleEditUserFailure()
    }
  }

  handleFetchLogout(prevProps) {
    if (prevProps.loginPage.logoutAPI.fetchStatus === FETCH_STATUS_REQUEST && this.props.loginPage.logoutAPI.fetchStatus === FETCH_STATUS_SUCCESS) {
      if (this.props.auth0.user) {
        this.props.auth0.logout({ returnTo: `${window.location.origin}/login` })
      }
      this.goToPage(FRONTEND_ROUTES.loginRoute)
    }
  }

  handleEditUserFailure() {
    const statusCode = this.props.loginPage.loadEditUser.error.status
    fetchErrorHandler(
      statusCode,
      {
        401: () => this.forceLogin()
      },
      this.showDefaultFailureModal
    )
  }

  showDefaultFailureModal = () => {
    this.setShowFailureModalData(
      this.getMessage('error_dialog_title_default'),
      this.getMessage('error_dialog_message_default'),
      this.onTryAgainClick,
      this.getMessage('try_again_button')
    )
  }

  forceLogin() {
    this.props.onPageChanged(FRONTEND_ROUTES.loginRoute)
  }

  getMessage(property) {
    const msg = MESSAGE[this.props.currentUser ? this.props.currentUser.language : 'EN']
    return msg[property]
  }

  setShowFailureModalData = (title, message, onPrimaryButtonClick, primaryButtonText) => {
    this.setState({
      failureModalData: {
        show: true,
        title,
        message,
        onPrimaryButtonClick,
        primaryButtonText
      }
    })
  }

  setHideFailureModalData = () => {
    this.setState({
      failureModalData: {
        show: false,
        title: '',
        message: '',
        onPrimaryButtonClick: undefined,
        primaryButtonText: ''
      }
    })
  }

  onTryAgainClick = () => {
    this.setHideFailureModalData()
    window.location.reload()
  }

  handleFetchAutoLoginFailure() {
    this.props.history.push(FRONTEND_ROUTES.loginRoute)
  }

  _handleRouterSiteChanged(prevProps) {
    if (this._shouldFetchNewNotifications(prevProps)) {
      this.connectSocketNotifications()
    }
  }

  _shouldFetchNewNotifications(prevProps) {
    const feature = this.props.location.pathname.split('/')[2]
    const prevProjectId = prevProps.location.pathname.split('/')[3]
    const currentProjectId = this.props.location.pathname.split('/')[3]
    const prevSiteId = prevProps.location.pathname.split('/')[4]
    const currentSiteId = this.props.location.pathname.split('/')[4]
    const isFeatureValid = feature === FEATURE_SITE
    const isProjectIdValid = currentProjectId !== undefined
    const isSiteIdValid = currentSiteId !== undefined
    const isProjectIdHasChanged = prevProjectId !== currentProjectId
    const isSiteIdHasChanged = prevSiteId !== currentSiteId
    const isLocationChanged = isProjectIdHasChanged || isSiteIdHasChanged
    return isFeatureValid && isProjectIdValid && isSiteIdValid && isLocationChanged
  }

  async connectSocketNotifications() {
    const currentSiteId = this.props.location.pathname.split('/')[4]
    const projectID = this.props.location.pathname.split('/')[3]
    await this.props.setCurrentProjectCookie(projectID)
    this.props.connectSocket()
    this.props.getListNotification(currentSiteId)
  }

  async connectSocketForProjectManagePage() {
    const projectID = this.props.location.pathname.split('/')[3]
    await this.props.setCurrentProjectCookie(projectID)
    this.props.connectSocket()
  }

  getApplicationContentClassName() {
    let className = 'application-content-container'
    const feature = this.props.location.pathname.split('/')[2]
    const activeSiteId = this.props.location.pathname.split('/')[4]
    if (this.state.showNotificationSidebar) {
      className += ' show-right-bar'
    }
    if (feature !== FEATURE_SITE || (feature === FEATURE_SITE && !activeSiteId)) {
      className += ' no-right-bar'
    }
    return className
  }

  handleHideNotificationSidebar() {
    this.setState({
      showNotificationSidebar: false
    })
  }

  handleExpandNotificationSidebar() {
    this.setState({
      showNotificationSidebar: true
    })
  }

  async routeToPage(cameraId, siteId) {
    await this.props.setUseCurrentDateFilter(true)
    const projectID = this.props.location.pathname.split('/')[3]
    this.props.history.push(`${FRONTEND_ROUTES.selectSiteRoute}/${projectID}/${siteId}/violation/${cameraId}`)
  }

  goToPage = async (path) => {
    await this.props.setUseCurrentDateFilter(false)
    this.props.history.push(path)
  }

  getNotificationRightBar() {
    let output = null
    const feature = this.props.location.pathname.split('/')[2]
    const activeSiteId = this.props.location.pathname.split('/')[4]
    if (feature === FEATURE_SITE && activeSiteId) {
      output = (
        <NotificationSidebar
          location={this.props.location}
          expand={this.state.showNotificationSidebar}
          onHideNotificationSidebar={() => this.handleHideNotificationSidebar()}
          onExpandNotificationSidebar={() => this.handleExpandNotificationSidebar()}
          onEachNotificationClick={(id, siteId) => this.routeToPage(id, siteId)}
          language={this.props.currentUser ? this.props.currentUser.language : 'EN'}
        />
      )
    }
    return output
  }

  getLoading() {
    let output = null
    if (this.props.loadUserStatus === FETCH_STATUS_REQUEST || this.props.auth0.isLoading) {
      output = (
        <ModalOverlay>
          <Spinner />
        </ModalOverlay>
      )
    }
    return output
  }

  handleLogoutClicked = () => {
    this.props.logout()
  }

  handleLanguageChanged = (language) => {
    const updatedUserData = { ...this.props.currentUser, language }
    this.props.editUser(updatedUserData)
  }

  getAllRoute() {
    let loggedInRoute = null
    if (this.props.currentUser) {
      loggedInRoute = (
        <Switch>
          <Route exact path={FRONTEND_ROUTES.homeRoute} render={() => <Redirect to={FRONTEND_ROUTES.selectSiteRoute} />} />
          <Route exact path={FRONTEND_ROUTES.selectSiteRoute} component={SelectSitePage} />
          <Route exact path={`${FRONTEND_ROUTES.selectSiteRoute}/:projectID/:siteId/dashboard`} component={DashboardPage} />
          <Route path={`${FRONTEND_ROUTES.selectSiteRoute}/:projectID/:siteId/violation`} component={ViolationDetailPage} />
          <Route path={`${FRONTEND_ROUTES.selectSiteRoute}/:projectID/:siteId/zone`} component={CameraZonePage} />
          <Route exact path={FRONTEND_ROUTES.projectManagementRoute} component={ProjectPage} />
          <Route exact path={`${FRONTEND_ROUTES.projectManagementRoute}/:projectID`} component={ProjectDetailPage} />
          <Route exact path={FRONTEND_ROUTES.userManagementRoute} component={UserManagementPage} />
          <Route exact path={FRONTEND_ROUTES.apiKeyRoute} component={APIKeyPage} />
        </Switch>
      )
    }
    return loggedInRoute
  }

  getFailureModal() {
    let output = null
    if (this.state.failureModalData.show) {
      output = (
        <ModalNotification
          className={'default-failure-modal'}
          title={this.state.failureModalData.title}
          message={this.state.failureModalData.message}
          onPrimaryButtonClick={this.state.failureModalData.onPrimaryButtonClick}
          primaryButtonText={this.state.failureModalData.primaryButtonText}
        />
      )
    }
    return output
  }

  handleShowOfflineModal = () => {
    this.setState({
      showOfflineModal: true
    })
  }

  handleCloseOfflineModal = () => {
    this.setState({
      showOfflineModal: false
    })
  }
  getOfflineModal = () => {
    const className = this.state.showOfflineModal ? 'offline-modal' : 'offline-modal hide'
    return (
      <OfflineModal
        className={className}
        title={this.getMessage('offline_modal_title')}
        message={this.getMessage('offline_modal_message')}
        onPrimaryButtonClick={this.handleCloseOfflineModal}
        primaryButtonText={this.getMessage('ok_btn')}
      />
    )
  }

  render() {
    return (
      <ApplicationLayoutStyled>
        <Sidebar
          location={this.props.location}
          listProject={this.props.listProject}
          currentUser={this.props.currentUser}
          onPageChanged={this.goToPage}
          onLogout={this.handleLogoutClicked}
          onLanguageChanged={this.handleLanguageChanged}
        />
        <div className={this.getApplicationContentClassName()}>{this.getAllRoute()}</div>
        {this.getNotificationRightBar()}
        {this.getLoading()}
        {this.getFailureModal()}
        {this.getOfflineModal()}
      </ApplicationLayoutStyled>
    )
  }
}

ApplicationLayout.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  loginPage: PropTypes.object.isRequired,
  listProject: PropTypes.array.isRequired,
  isLoggedIn: PropTypes.func.isRequired,
  setUseCurrentDateFilter: PropTypes.func.isRequired,
  loadUserStatus: PropTypes.string.isRequired,
  currentUser: PropTypes.object,
  setCurrentProjectCookie: PropTypes.func.isRequired,
  connectSocket: PropTypes.func.isRequired,
  getListNotification: PropTypes.func.isRequired
}

/* istanbul ignore next */
const mapStateToProps = (state) => {
  return {
    loadUserStatus: state.loginPage.loginAPI.fetchStatus,
    loadUserSSOStatus: state.loginPage.ssoLoginAPI.fetchStatus,
    currentUser: state.loginPage.loginAPI.userData,
    loginPage: state.loginPage,
    listProject: state.selectSitePage.listProject
  }
}

/* istanbul ignore next */
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(Object.assign({}, LoginActions, ApplicationActions, NotificationActions, SocketActions, ProjectDetailsActions), dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(withAuth0(ApplicationLayout))
